import { Flex, Heading, VStack } from '@chakra-ui/react';
import { ProdClient } from 'client/client';
import { colorScheme } from 'lib/colorScheme';
import cacheData from 'memory-cache';
import { CustomForm as CustomFormModel } from 'models';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { moduleMaxWidth } from 'themes/overrides';
import { InbuiltFormData } from 'utils/types/formData';

import { InbuiltForm as InbuiltFormModal } from '../../models/content-types/inbuilt_form';
import { FormWrapper } from './FormWrapper';
import { InbuiltCustomForm } from './InbuiltCustomForm';

interface InbuiltFormProps {
  model: InbuiltFormModal;
  onSubmit?: () => void;
}

export const InbuiltForm: FunctionComponent<InbuiltFormProps> = ({ model, onSubmit }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;

  const backgroundColor = model.elements.backgroundColor.value;
  const bgColor = colorScheme[backgroundColor[0].codename as keyof typeof colorScheme].base;

  useEffect(() => {
    if (model.elements.customFormElement.linkedItems.length > 0) {
      const fetchCustomForm = async () => {
        const response = await ProdClient.items<CustomFormModel>()
          .equalsFilter('system.codename', model.elements.customFormElement.linkedItems[0].system.codename)
          .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
          .depthParameter(9)
          .toPromise();
        // console.log('customformdata',response.data.items as CustomFormModel[])
        cacheData.put('customFormData', response.data.items as CustomFormModel[]);
        return response.data.items as CustomFormModel[];
      };
      fetchCustomForm();
    }
    // const genRandomKey = async () => {
    //   console.log(await ecc.randomKey())
    // };

    // genRandomKey();
  }, []);

  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    headline: headline,
    backgroundColor: bgColor,
    submittedMessageAlignment: 'center',
    fieldColor: bgColor === 'white' ? 'gray.98' : 'white',
  } as InbuiltFormData;

  return (
    <>
      <VStack
        w='100vw'
        maxW={moduleMaxWidth}
        mx='auto'
        align='stretch'
        id={model.system.codename}
        bg={data.backgroundColor}
        px={{ base: 'xs', md: 'l' }}
        spacing={{
          base: 's',
          md: 'm',
        }}
        mt='10px'
      >
        {cacheData.get('customFormData') && <InbuiltCustomForm model={cacheData.get('customFormData')[0]} />}
        <Flex alignItems={'center'} w='100%' flexDir='column' p={{ base: 's', lg: 'l' }}>
          <Flex gap={{ base: 's', lg: 'm' }} flexDir='column' w={{ base: '100%', lg: '50%', xl: '60%' }}>
            {/* text */}
            {!hasBeenSubmitted && (
              <Heading textAlign={'center'} as='h1' color='#0098EB' textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}>
                {data.headline}
              </Heading>
            )}
            {/* form */}
            <FormWrapper
              data={data}
              onSubmit={() => {
                setHasBeenSubmitted(true);
                if (onSubmit) {
                  onSubmit();
                }
              }}
            />
          </Flex>
        </Flex>
      </VStack>
    </>
  );
};
