import { AspectRatio, Box, Button, Collapse, Flex, Text, useDisclosure, useMediaQuery, VStack } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { IconComp } from 'components/base/IconComp';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { MediaBackgroundHero as MediaBackgroundHeroProps } from '../../models/content-types/media_background_hero';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';
import { HyphenResult } from '../hyphen/hyphenation';
import { ContactFormModal } from './ContactFormModal';

const MotionBox = motion(Box);

interface MbhProps {
  model: MediaBackgroundHeroProps;
  customBreadcrumb?: BackLink;
}
export const MediaBackgroundHero: FC<MbhProps> = ({ model, customBreadcrumb }) => {
  const router = useRouter();
  const [isSmallScreen] = useMediaQuery('(max-width: 1024px)');
  const translations = withTranslations();
  // ---{ Start logic for scrolling Engage button styles}---
  const containerRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLDivElement>(null);
  const [shouldShowCtaText, setShouldShowCtaText] = useState(true);
  const [ctaPosition, setCtaPosition] = useState('absolute');
  const [windowRatio, setWindowRatio] = useState<[number, number]>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCtaPositioning = () => {
    setCtaPosition('fixed');
  };

  useEffect(() => {
    handleCtaPositioning();
  }, []);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let isTicking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        isTicking = false;
        return;
      }
      setShouldShowCtaText(scrollY < lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      isTicking = false;
    };

    const onScroll = () => {
      if (!isTicking) {
        window.requestAnimationFrame(updateScrollDir);
        isTicking = true;
      }
    };
    if (window !== undefined) setWindowRatio([window.innerWidth, window.innerHeight]);

    window.addEventListener('scroll', onScroll);
    window.addEventListener('touchmove', onScroll, false);
    return () => {
      window.removeEventListener('scroll', onScroll, false);
      window.removeEventListener('touchmove', onScroll, false);
    };
  }, [shouldShowCtaText]);
  // ---{ End logic for scrolling Engage button styles}---
  const hyphenation = model.elements.enableHyphenation?.value[0]?.codename;
  const imgAltText = model.elements.alternativeText?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const focalpointLeftvalue = model.elements.leftFocalPoint.value ? `${model.elements.leftFocalPoint.value}% ` : '50%';
  const focalpointRightvalue = model.elements.rightFocalPoint.value
    ? `${model.elements.rightFocalPoint.value}%`
    : '50%';

  const focalpointvalue = focalpointLeftvalue + focalpointRightvalue;
  // const focalPoints = imageData.focalPoint ? imageData.focalPoint : [0.5, 0.5];

  let video: any;
  // let shouldAutoplayOnDesktop: any;
  if (
    model.elements.imageVideo.linkedItems[0]?.elements &&
    'autoplay' in model.elements.imageVideo.linkedItems[0].elements
  ) {
    //  let shouldAutoplayOnDesktop =
    model.elements.imageVideo.linkedItems[0].elements?.autoplay?.value?.[0]?.name !== undefined;

    if (
      model.elements.imageVideo.linkedItems[0]?.elements &&
      'video23Link' in model.elements.imageVideo.linkedItems[0].elements
    ) {
      const videoLink = model.elements.imageVideo.linkedItems[0].elements.video23Link;

      if (videoLink) {
        video = videoLink.value
          .replaceAll('allowfullscreen', '')
          .replace(/padding-bottom:\s*\d+%/g, 'padding-bottom: 56.25%');
      }
    }
  }
  const imageData = imageFormatter(model.elements.imageFrontify?.value, imgAltText);
  const videoRef = useRef<HTMLVideoElement>(null);
  // const isInView = useInView(videoRef, {
  //   margin: '-25% 25%',
  //   once: true,
  // });

  // useEffect(() => {
  //   if (shouldAutoplayOnDesktop && isInView) {
  //     videoRef.current?.click();
  //   }
  // }, [isInView]);
  const subheadline = model.elements.subheadline.value;
  const headline = model.elements.headline.value;
  const body = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.body,
  }).html;
  const engageOrViewMoreCTA: string = model.elements.ctaOptions.value[0].codename;
  const hideBreadcrumb =
    model.elements.hideBreadcrumb.value.length && model.elements.hideBreadcrumb.value[0].codename === 'yes';

  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const breadcrumbOverride = model.elements.breadcrumbOverride.linkedItems.length
    ? ({
        href: hrefLink(
          model.elements.breadcrumbOverride.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: model.elements.breadcrumbOverride.linkedItems[0].elements.summaryTitle.value,
      } as BackLink)
    : customBreadcrumb != undefined
    ? customBreadcrumb
    : undefined;

  const url = hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  const hyperheadline = HyphenResult(headline, router.locale);
  const desc = HyphenResult(body, router.locale);
  const CTAVariants = (codename: string) => {
    switch (codename) {
      case 'view_more':
        const fileFrontify = model.elements.ctaSnippetFrontifyFile.value
          ? JSON.parse(model.elements.ctaSnippetFrontifyFile.value)
          : undefined;
        return (
          <Button
            as='a'
            borderRadius='4px'
            cursor={'pointer'}
            href={fileFrontify ? fileFrontify.downloadUrl : url}
            download={fileFrontify}
            size='medium'
            mt='s'
            target={model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab' ? '_blank' : ''}
            textStyle={'desktop.button'}
          >
            {model.elements.ctaSnippetCtaText.value}
          </Button>
        );
      case 'engage_with_us':
        if (
          model.elements.formModalCtaButtonText.value !== '' &&
          model.elements.formModalCtaFormModal.value.length > 0
        ) {
          return (
            <Box
              ref={ctaRef}
              bottom={'0'}
              sx={{ position: { base: 'fixed', lg: ctaPosition } }}
              right={`0`}
              zIndex={3}
              cursor={'pointer'}
            >
              <Button
                size={{ base: 'medium', lg: 'large' }}
                aria-controls={model.elements.formModalCtaFormModal.linkedItems[0].system.name}
                borderTopLeftRadius={'4px'}
                borderRadius='4px'
                onClick={onOpen}
              >
                <IconComp iconName='message' />
                <Collapse in={shouldShowCtaText}>
                  <Text pl='xs' textStyle={'desktop.button'}>
                    {model.elements.formModalCtaButtonText.value}
                  </Text>
                </Collapse>
              </Button>
              <ContactFormModal
                id={`${model.system.id}${model.elements.formModalCtaFormModal.linkedItems[0].system.id}`}
                model={model.elements.formModalCtaFormModal.linkedItems[0]}
                isOpen={isOpen}
                onClose={onClose}
              />
            </Box>
          );
        }
        break;
      default:
        return null;
    }
  };
  return (
    <Box
      ref={containerRef}
      id={model.system.codename}
      w='100vw'
      maxW={moduleMaxWidth}
      mx='auto'
      px={{ base: 's', lg: 's' }}
      position={'relative'}
      color='white'
      h={
        video
          ? { base: 'calc(120vh - 810px)', md: 'calc(120vh - 500px)', lg: 'calc(120vh - 90px)' }
          : { base: 'calc(100vh - 70px)', lg: 'calc(100vh - 100px)' }
      }
      display='flex'
      _first={{ alignSelf: 'strech' }}
      overflow='clip'
    >
      {!video && (
        <>
          <VStack
            zIndex={3}
            pt={{ base: 's', lg: 'm' }}
            px={{ base: 'xs', lg: 's' }}
            flexDirection='row'
            flexWrap='wrap'
            alignItems='unset'
            w='100%'
            maxW='780px'
            h='100%'
            gap='0'
            alignContent={hideBreadcrumb && model.elements.heroTypeCampaignOnly.value[0]?.codename ? 'center' : 'unset'}
          >
            {!hideBreadcrumb && (
              <Box justifySelf='start' position='relative' top='0'>
                <Breadcrumb color='white' customLink={breadcrumbOverride} />
              </Box>
            )}
            <Box position='relative' alignSelf={{ base: 'center', lg: 'unset' }} width={{ base: '100%', lg: '66vw' }}>
              {/* TODO: CRITICAL!! TEMP FOR CAMPAGIN */}
              {/* h1 on headline */}
              {/* headline: textStyle={{ base: 'mobile.h2', md: 'tablet.h2', lg: 'desktop.h2' }} */}
              {/* subheadline: textStyle={{ base: 'mobile.h4', md: 'desktop.h5' }} */}
              {model.elements.heroTypeCampaignOnly.value[0]?.codename ? (
                <Flex flexDirection='column-reverse'>
                  <Text
                    as={subheadline.length ? 'p' : 'h1'}
                    textStyle={
                      subheadline.length
                        ? { base: 'mobile.h2', lg: 'desktop.h3' }
                        : { base: 'mobile.campaign.h1', md: 'desktop.campaign.h2' }
                    }
                  >
                    {/* {headline} */}
                    {hyphenation == 'yes' ? hyperheadline : headline}
                  </Text>
                  <Text as='h1' textStyle={{ base: 'mobile.campaign.h1', md: 'desktop.campaign.h2' }}>
                    {subheadline}
                  </Text>
                </Flex>
              ) : (
                <Flex flexDirection='column-reverse'>
                  <Text as='h1' textStyle={{ base: 'mobile.h2', md: 'tablet.h2', lg: 'desktop.h2' }}>
                    {hyphenation == 'yes' ? hyperheadline : headline}
                  </Text>
                  <Text textStyle={{ base: 'mobile.h4', md: 'desktop.h5' }}>{subheadline}</Text>
                </Flex>
              )}
            </Box>
            <Box
              alignSelf={{ base: 'flex-start', lg: 'flex-end' }}
              maxW={{ lg: '420px' }}
              pb={{ base: 'lr', lg: 'l' }}
              px=''
              textStyle={{ base: 'mobile.body.s', md: 'desktop.body.book.l' }}
            >
              <>{body && <Box dangerouslySetInnerHTML={{ __html: hyphenation == 'yes' ? desc : body }} />}</>
              <>{engageOrViewMoreCTA === 'view_more' && CTAVariants(engageOrViewMoreCTA)}</>
            </Box>
          </VStack>
          <Box
            w={{ base: 'calc(100% - 20px)', lg: 'calc(100% - 40px)' }}
            h='100%'
            position='absolute'
            right='0'
            background={{
              base: 'linear-gradient(90deg, rgba(39, 57, 67, 0.4) 0%, rgba(39, 57, 67, 0) 100%);',
              lg: 'linear-gradient(90deg, rgba(39, 57, 67, 0.4) 41.95%, rgba(39, 57, 67, 0) 64.08%);',
            }}
            zIndex={1}
          ></Box>
        </>
      )}
      <AspectRatio position='initial' zIndex={0} ratio={windowRatio ?? 16 / 9}>
        {video ? (
          <Box
            style={{
              aspectRatio: '16 / 9', // Adjust the aspect ratio as needed
              width: '100%',
              height: '100%',
              paddingLeft: isSmallScreen ? '20px' : '0px',
              paddingRight: isSmallScreen ? '20px' : '0px',
            }}
            display='flex'
            justifyContent='center'
          >
            <MotionBox
              ref={videoRef}
              loop
              w='inherit'
              h='inherit'
              position='absolute'
              bottom={150}
              top={1}
              left={0}
              objectFit='cover'
            >
              {video && <Box id={`video-${model.system.id}`} dangerouslySetInnerHTML={{ __html: video }} />}
            </MotionBox>
          </Box>
        ) : (
          <Image
            style={{
              paddingLeft: isSmallScreen ? '20px' : '40px',
              paddingRight: isSmallScreen ? '20px' : '40px',
              alignSelf: 'center',
              objectFit: 'cover',
              objectPosition: focalpointvalue,
            }}
            width={imageData.width}
            height={imageData.height}
            src={imageData.src}
            alt={imageData.alt}
            quality={compressimage}
          />
        )}
      </AspectRatio>

      <>{engageOrViewMoreCTA === 'engage_with_us' && CTAVariants(engageOrViewMoreCTA)}</>
    </Box>
  );
};
