import { AspectRatio, Box, Flex, Grid, Text, useMediaQuery } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import Image from 'next/image';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { imageFormatter } from 'utils/imageFormatter';

import { colorScheme } from '../../lib/colorScheme';
import { ImageGallery as ImageGalleryModel, ImageGalleryItem as ImageGalleryItemModel } from '../../models';
import { IconComp } from '../base/IconComp';

interface ImageGalleryProps {
  model: ImageGalleryModel;
}

const getImgData = (index: number, imageGalleryItem: ImageGalleryItemModel) => {
  return {
    index: index,
    imageData: imageFormatter(
      imageGalleryItem.elements.mediaFrontify.value,
      imageGalleryItem.elements.alternativeText.value,
    ),
    description: createRichTextHtmlResolver(nodeParser).resolveRichText({
      element: imageGalleryItem.elements.description,
    }).html,
    photoCredits: imageGalleryItem.elements.photoCredits.value,
    compressimage: imageGalleryItem.elements.mediaFrontifyCompression.value
      ? imageGalleryItem.elements.mediaFrontifyCompression.value
      : 75,
  };
};

export const ImageGallery: FunctionComponent<ImageGalleryProps> = ({ model }) => {
  const { aria } = withTranslations();
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);
  const modalScrollContainerRef = useRef(null);

  const [isSmallScreen] = useMediaQuery('(max-width: 900px)');

  const [currentImg, setCurrentImg] = useState(getImgData(0, model.elements.imageGalleryItem.linkedItems[0]));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalScrollContainerWidth, setModalScrollContainerWidth] = useState();
  const [screenOrientationType, setScreenOrientationType] = useState<OrientationType>();

  useEffect(() => {
    setScreenOrientationType(screen.orientation.type);
    screen.orientation.addEventListener('change', () => {
      setScreenOrientationType(screen.orientation.type);
    });
  }, []);

  useEffect(() => {
    setModalScrollContainerWidth(modalScrollContainerRef.current?.['clientWidth']);
    window.addEventListener('resize', () => {
      setModalScrollContainerWidth(modalScrollContainerRef.current?.['clientWidth']);
    });
  }, [isModalOpen]);

  const data = {
    colorScheme: colorScheme[model.elements.backgroundColor.value[0].codename as keyof typeof colorScheme],
    headline: model.elements.headline.value,
    imageItems: model.elements.imageGalleryItem.linkedItems.map((imageGalleryItem, index) =>
      getImgData(index, imageGalleryItem),
    ),
  };

  scrollContainerRef.current?.addEventListener('scroll', () => {
    const scrollLeft = scrollContainerRef.current!.scrollLeft;
    const scrollWidth = scrollContainerRef.current!.scrollWidth;
    const clientWidth = scrollContainerRef.current!.clientWidth;

    const scrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
    const imagePercentage = 100 / data.imageItems.length;

    const changePoints: number[] = [];
    for (let i = 1; i < data.imageItems.length; i++) {
      changePoints.push(imagePercentage * i);
    }

    changePoints.forEach((n, index) => {
      if (scrollPercentage < changePoints[0]) {
        setCurrentImg(data.imageItems.find((item) => item.index === 0) ?? currentImg);
      }
      if (scrollPercentage > n) {
        setCurrentImg(data.imageItems.find((item) => item.index === index + 1) ?? currentImg);
      }
    });
  });
  return (
    <Box
      w='100vw'
      maxW={moduleMaxWidth}
      id={model.system.codename}
      bgGradient={{
        base: `linear(${data.colorScheme.base} 45%, white 25%)`,
        '2md': `linear(${data.colorScheme.base} 75%, white 25%)`,
      }}
    >
      <Box maxW={'1440px'} py='s' pl={{ base: 'xs', '2md': 'l' }} mx='auto'>
        <Text as='h2' mb={{ base: '30px', md: 's' }} textStyle={{ base: 'mobile.body.l', md: 'desktop.h5' }}>
          {data.headline}
        </Text>
        <Grid
          ref={scrollContainerRef}
          overflowX={{ base: 'auto', '2md': 'hidden' }}
          overflowY='hidden'
          sx={{
            '::-webkit-scrollbar': {
              bg: 'mountain.20',
              height: '3px',
            },
            '::-webkit-scrollbar-thumb': {
              bg: 'cyan.web',
            },
          }}
          templateColumns={{
            base: `repeat(${data.imageItems.length}, 84.8%)`,
            '2md': `repeat(${data.imageItems.length}, 90.91%)`,
          }}
          columnGap={{ base: 's', '2md': 'm' }}
          rowGap={{ base: '30px', '2md': 'xs' }}
          pb={{ base: 's', '2md': '0' }}
          pr={{ base: 's', '2md': '0' }}
          _focus={{
            borderColor: 'cyan.web',
            borderWidth: '2px ',
          }}
        >
          {data.imageItems.map((imageItem, index) => {
            return (
              <Box key={index}>
                <AspectRatio as='figure' ratio={16 / 9} onClick={() => isSmallScreen && setIsModalOpen(true)}>
                  <Image
                    style={{
                      objectFit: 'cover',
                      transform: isSmallScreen
                        ? 'none'
                        : `translateX(calc(-${100 * currentImg.index}% - ${currentImg.index * 60}px))`,
                      transition: 'transform 1s ease',
                    }}
                    src={imageItem.imageData.src}
                    alt={imageItem.imageData.alt}
                    width={imageItem.imageData.width}
                    height={imageItem.imageData.height}
                    quality={imageItem.compressimage}
                  />
                </AspectRatio>
                <Box as='figcaption' visibility={'hidden'} w='0' h='0'>
                  <Box textStyle={'mobile.body.xs'} dangerouslySetInnerHTML={{ __html: imageItem.description }} />
                  <Text textStyle={'mobile.body.xs'}>{imageItem.photoCredits}</Text>
                </Box>
              </Box>
            );
          })}
          <Flex w={'100%'} gap='xs' justifyContent='space-between' position='sticky' left='0'>
            <Box aria-hidden='true'>
              <Text mb='xxs' textStyle={'mobile.body.s'}>
                {currentImg.index + 1} / {data.imageItems.length}
              </Text>
              <Box textStyle={'mobile.body.xs'} dangerouslySetInnerHTML={{ __html: currentImg.description }} />
              <Text textStyle={'mobile.body.xs'}>{currentImg.photoCredits}</Text>
            </Box>
            {!isSmallScreen && (
              <Flex gap='xxs' w='fit-content' h='fit-content'>
                <Box
                  aria-label={aria('previousImage')}
                  as='button'
                  onClick={() =>
                    setCurrentImg(data.imageItems.find((item) => item.index === currentImg.index - 1) ?? currentImg)
                  }
                >
                  <IconComp iconName='prev' styleName={currentImg.index === 0 ? 'disabled' : 'default'} />
                </Box>
                <Box
                  aria-label={aria('nextImage')}
                  as='button'
                  onClick={() =>
                    setCurrentImg(data.imageItems.find((item) => item.index === currentImg.index + 1) ?? currentImg)
                  }
                >
                  <IconComp
                    iconName='next'
                    styleName={currentImg.index === data.imageItems.length - 1 ? 'disabled' : 'default'}
                  />
                </Box>
              </Flex>
            )}
          </Flex>
        </Grid>
        {isModalOpen && (
          <Flex
            ref={modalScrollContainerRef}
            bg='mountain.base'
            zIndex={11}
            flexDir='column'
            position='fixed'
            top='0'
            left='0'
            w='100vw'
            h='100vh'
            overflow='auto'
          >
            <Box
              position='sticky'
              w='fit-content'
              top='xxs'
              left={modalScrollContainerWidth ? modalScrollContainerWidth - 50 : 'xxs'}
              as='button'
              zIndex={10}
              h='0px'
              onClick={() => setIsModalOpen(false)}
            >
              <IconComp
                iconName={'close'}
                chakraStyle={{ bg: 'white', borderRadius: '50%', p: '8px', svg: { w: '24px', h: '24px' } }}
              />
            </Box>
            <Flex
              gap='s'
              flexDir={screenOrientationType === 'portrait-primary' ? 'column' : 'row'}
              h={screenOrientationType === 'portrait-primary' ? 'auto' : '100vh'}
            >
              {data.imageItems.map((imageItem, index) => {
                return (
                  <Image
                    key={index}
                    width={
                      screenOrientationType === 'portrait-primary' ? imageItem.imageData.width : window.innerWidth * 0.8
                    }
                    height={imageItem.imageData.height}
                    style={{ objectFit: 'cover' }}
                    src={imageItem.imageData.src}
                    alt={imageItem.imageData.alt}
                    quality={50}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
